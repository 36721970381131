var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        top: "0",
        "custom-class": "contactDialog"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c("div", { staticClass: "dialogBody" }, [
        _c("span", {
          staticClass: "iconfont_Me icon-x deleteIconStyle",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.dialogBeforeClose.apply(null, arguments)
            }
          }
        }),
        _c("div", { staticClass: "container" }, [
          _c(
            "div",
            { staticClass: "photoBox" },
            [
              _c(
                "el-upload",
                {
                  staticClass: "avatar-uploader",
                  attrs: {
                    action: "/",
                    disabled: _vm.type == "read",
                    "http-request": _vm.handlerUploadFile,
                    "show-file-list": false
                  }
                },
                [
                  _vm.type != "read"
                    ? _c("div", { staticClass: "upload" }, [
                        _c("span", {
                          staticClass: "icon-shot iconfont_Me iconStyle"
                        })
                      ])
                    : _vm._e(),
                  _c(
                    "el-image",
                    {
                      staticStyle: { width: "100%", height: "100%" },
                      attrs: { src: _vm.inputs.profilePhoto, fit: "cover" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "image-slot",
                          style: {
                            width: "100%",
                            height: "100%",
                            overflow: "hidden",
                            background: "#A6A6A6",
                            display: "flex",
                            "align-items": "center",
                            "justify-content": "center"
                          },
                          attrs: { slot: "error" },
                          slot: "error"
                        },
                        [
                          _c("i", {
                            staticClass: "iconStyle iconfont_Me icon-user-fill"
                          })
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "scroll" }, [
            _c("div", { staticClass: "inputBox" }, [
              _c(
                "div",
                { staticClass: "iconBox" },
                [
                  _c("el-image", {
                    staticStyle: { width: "23px", height: "23px" },
                    attrs: {
                      src: require("../../../assets/images/contact/user.png"),
                      fit: "fill"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "inputBody",
                  style: {
                    background: "transparent"
                  }
                },
                [
                  _vm.type != "read"
                    ? _c("el-input", {
                        attrs: { placeholder: "Name" },
                        model: {
                          value: _vm.inputs.name,
                          callback: function($$v) {
                            _vm.$set(_vm.inputs, "name", $$v)
                          },
                          expression: "inputs.name"
                        }
                      })
                    : _vm._e(),
                  _vm.type != "read"
                    ? _c("span", { staticClass: "required" }, [_vm._v("*")])
                    : _c("span", { staticClass: "textStyle" }, [
                        _vm._v(_vm._s(_vm.inputs.name))
                      ])
                ],
                1
              ),
              _c("div", { staticClass: "line" })
            ]),
            _c("div", { staticClass: "inputBox" }, [
              _c(
                "div",
                { staticClass: "iconBox" },
                [
                  _c("el-image", {
                    staticStyle: { width: "23px", height: "23px" },
                    attrs: {
                      src: require("../../../assets/images/contact/email.png"),
                      fit: "fill"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "inputBody",
                  style: {
                    background: "transparent"
                  }
                },
                [
                  _vm.type != "read"
                    ? _c("el-input", {
                        attrs: { placeholder: "Email" },
                        model: {
                          value: _vm.inputs.email,
                          callback: function($$v) {
                            _vm.$set(_vm.inputs, "email", $$v)
                          },
                          expression: "inputs.email"
                        }
                      })
                    : _c("span", { staticClass: "textStyle" }, [
                        _vm._v(_vm._s(_vm.inputs.email))
                      ])
                ],
                1
              ),
              _c("div", { staticClass: "line" })
            ]),
            _c("div", { staticClass: "inputBox" }, [
              _c(
                "div",
                { staticClass: "iconBox" },
                [
                  _c("el-image", {
                    staticStyle: { width: "23px", height: "23px" },
                    attrs: {
                      src: require("../../../assets/images/contact/phone.png"),
                      fit: "fill"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "phoneBox" },
                _vm._l(_vm.inputs.phones, function(item, index) {
                  return _c("div", { key: index }, [
                    _c(
                      "div",
                      { staticClass: "phoneOutter" },
                      [
                        _vm.type != "read"
                          ? [
                              _c(
                                "div",
                                { staticClass: "select" },
                                [
                                  _c(
                                    "AddressSelectorForPhone",
                                    {
                                      on: {
                                        selectThisAreaCode:
                                          _vm.selectThisAreaCode
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "selectButton",
                                          on: {
                                            click: function($event) {
                                              _vm.selectIndex = index
                                            }
                                          }
                                        },
                                        [
                                          item.code != ""
                                            ? [
                                                _vm._v(
                                                  "\n                          +" +
                                                    _vm._s(item.code) +
                                                    "\n                        "
                                                )
                                              ]
                                            : [
                                                _c("span", {
                                                  staticClass:
                                                    "iconfont_Me icon-position positionIcon"
                                                })
                                              ],
                                          _c("span", {
                                            staticClass:
                                              "iconfont_Me icon-a-zu10330 selectIcon"
                                          })
                                        ],
                                        2
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "inputBody",
                                  style: {
                                    background: "transparent"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "number",
                                      placeholder: "Phone Number",
                                      onKeypress:
                                        "return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"
                                    },
                                    model: {
                                      value: item.phoneNumber,
                                      callback: function($$v) {
                                        _vm.$set(item, "phoneNumber", $$v)
                                      },
                                      expression: "item.phoneNumber"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.phonesLength > 1
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "deletePhone inputButton",
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.deletePhone(index)
                                        }
                                      }
                                    },
                                    [
                                      _c("span", {
                                        staticClass:
                                          "iconfont_Me icon-Frame2 iconStyle"
                                      })
                                    ]
                                  )
                                : _vm._e(),
                              item.phoneNumber != "" &&
                              index == _vm.phonesLength - 1
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "addPhone inputButton",
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.addPhone.apply(
                                            null,
                                            arguments
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("span", {
                                        staticClass:
                                          "iconfont_Me icon-addition iconStyle"
                                      })
                                    ]
                                  )
                                : _vm._e()
                            ]
                          : [
                              _c("div", { staticClass: "phoneText" }, [
                                _c(
                                  "span",
                                  { staticClass: "phoneStyle" },
                                  [
                                    item.code != ""
                                      ? [
                                          _vm._v(
                                            "\n                        + " +
                                              _vm._s(item.code) +
                                              "\n                      "
                                          )
                                        ]
                                      : _vm._e(),
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(item.phoneNumber) +
                                        "\n                    "
                                    )
                                  ],
                                  2
                                )
                              ])
                            ]
                      ],
                      2
                    ),
                    _c("div", { staticClass: "line" })
                  ])
                }),
                0
              )
            ]),
            _c(
              "div",
              { staticClass: "customBox" },
              _vm._l(_vm.inputs.fieldVos, function(field, index) {
                return _c("div", { key: index, staticClass: "inputBox" }, [
                  _c(
                    "div",
                    {
                      staticClass: "fieldBox",
                      style: {
                        background:
                          _vm.type != "read" ? "transparent" : "#F5F5F5"
                      }
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(field.fieldName) +
                          "\n            "
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "inputBody",
                      style: {
                        background: "transparent"
                      }
                    },
                    [
                      _vm.type != "read"
                        ? _c("el-input", {
                            attrs: { placeholder: field.fieldName },
                            model: {
                              value: field.fieldValue,
                              callback: function($$v) {
                                _vm.$set(field, "fieldValue", $$v)
                              },
                              expression: "field.fieldValue"
                            }
                          })
                        : _c("span", { staticClass: "textStyle" }, [
                            _vm._v(_vm._s(field.fieldValue))
                          ])
                    ],
                    1
                  ),
                  _c("div", { staticClass: "line" })
                ])
              }),
              0
            )
          ])
        ]),
        _c("div", { staticClass: "footer" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "waves",
                  rawName: "v-waves",
                  value: { color: "#ffffff" },
                  expression: "{ color: '#ffffff' }"
                }
              ],
              staticClass: "button",
              class: { active: _vm.type != "read" ? _vm.activeFlag : true },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.nextStep.apply(null, arguments)
                }
              }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.type != "read" ? "Save" : "Edit") +
                  "\n      "
              )
            ]
          ),
          _vm.type == "read"
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "waves",
                      rawName: "v-waves",
                      value: { color: "#ffffff" },
                      expression: "{ color: '#ffffff' }"
                    }
                  ],
                  staticClass: "button delete",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.deleteContact.apply(null, arguments)
                    }
                  }
                },
                [_vm._v("\n        Delete\n      ")]
              )
            : _vm._e()
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }