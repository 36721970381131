<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    top="0"
    custom-class="contactDialog"
  >
    <div class="dialogBody">
      <span
        class="iconfont_Me icon-x deleteIconStyle"
        @click.stop="dialogBeforeClose"
      ></span>
      <div class="container">
        <div class="photoBox">
          <el-upload
            class="avatar-uploader"
            action="/"
            :disabled="type == 'read'"
            :http-request="handlerUploadFile"
            :show-file-list="false"
          >
            <div class="upload" v-if="type != 'read'">
              <span class="icon-shot iconfont_Me iconStyle"></span>
            </div>
            <el-image
              style="width: 100%; height: 100%"
              :src="inputs.profilePhoto"
              :fit="'cover'"
            >
              <div
                slot="error"
                class="image-slot"
                :style="{
                  width: '100%',
                  height: '100%',
                  overflow: 'hidden',
                  background: '#A6A6A6',
                  display: 'flex',
                  'align-items': 'center',
                  'justify-content': 'center',
                }"
              >
                <i class="iconStyle iconfont_Me icon-user-fill"></i>
              </div>
            </el-image>
          </el-upload>
        </div>
        <div class="scroll">
          <div class="inputBox">
            <div class="iconBox">
              <el-image
                style="width: 23px; height: 23px"
                :src="require('../../../assets/images/contact/user.png')"
                :fit="'fill'"
              ></el-image>
            </div>
            <div
              class="inputBody"
              :style="{
                background: 'transparent',
              }"
            >
              <el-input
                v-if="type != 'read'"
                v-model="inputs.name"
                placeholder="Name"
              ></el-input>
              <span v-if="type != 'read'" class="required">*</span>
              <span v-else class="textStyle">{{ inputs.name }}</span>
            </div>
            <div class="line"></div>
          </div>
          <div class="inputBox">
            <div class="iconBox">
              <el-image
                style="width: 23px; height: 23px"
                :src="require('../../../assets/images/contact/email.png')"
                :fit="'fill'"
              ></el-image>
            </div>
            <div
              class="inputBody"
              :style="{
                background: 'transparent',
              }"
            >
              <el-input
                v-if="type != 'read'"
                v-model="inputs.email"
                placeholder="Email"
              ></el-input>
              <span v-else class="textStyle">{{ inputs.email }}</span>
            </div>
            <div class="line"></div>
          </div>
          <div class="inputBox">
            <div class="iconBox">
              <el-image
                style="width: 23px; height: 23px"
                :src="require('../../../assets/images/contact/phone.png')"
                :fit="'fill'"
              ></el-image>
            </div>
            <div class="phoneBox">
              <div v-for="(item, index) in inputs.phones" :key="index">
                <div class="phoneOutter">
                  <template v-if="type != 'read'">
                    <div class="select">
                      <AddressSelectorForPhone
                        @selectThisAreaCode="selectThisAreaCode"
                      >
                        <div class="selectButton" @click="selectIndex = index">
                          <template v-if="item.code != ''">
                            +{{ item.code }}
                          </template>
                          <template v-else>
                            <span
                              class="iconfont_Me icon-position positionIcon"
                            ></span>
                          </template>
                          <span
                            class="iconfont_Me icon-a-zu10330 selectIcon"
                          ></span>
                        </div>
                      </AddressSelectorForPhone>
                    </div>
                    <div
                      class="inputBody"
                      :style="{
                        background: 'transparent',
                      }"
                    >
                      <el-input
                        type="number"
                        v-model="item.phoneNumber"
                        placeholder="Phone Number"
                        onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"
                      ></el-input>
                    </div>
                    <div
                      class="deletePhone inputButton"
                      @click.stop="deletePhone(index)"
                      v-if="phonesLength > 1"
                    >
                      <span class="iconfont_Me icon-Frame2 iconStyle"></span>
                    </div>
                    <div
                      class="addPhone inputButton"
                      @click.stop="addPhone"
                      v-if="item.phoneNumber != '' && index == phonesLength - 1"
                    >
                      <span class="iconfont_Me icon-addition iconStyle"></span>
                    </div>
                  </template>
                  <template v-else>
                    <div class="phoneText">
                      <span class="phoneStyle">
                        <template v-if="item.code != ''">
                          + {{ item.code }}
                        </template>
                        {{ item.phoneNumber }}
                      </span>
                    </div>
                  </template>
                </div>
                <div class="line"></div>
              </div>
            </div>
          </div>
          <div class="customBox">
            <div
              class="inputBox"
              v-for="(field, index) in inputs.fieldVos"
              :key="index"
            >
              <div
                class="fieldBox"
                :style="{
                  background: type != 'read' ? 'transparent' : '#F5F5F5',
                }"
              >
                {{ field.fieldName }}
              </div>
              <div
                class="inputBody"
                :style="{
                  background: 'transparent',
                }"
              >
                <el-input
                  v-if="type != 'read'"
                  v-model="field.fieldValue"
                  :placeholder="field.fieldName"
                ></el-input>
                <span v-else class="textStyle">{{ field.fieldValue }}</span>
              </div>
              <div class="line"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div
          class="button"
          v-waves="{ color: '#ffffff' }"
          :class="{ active: type != 'read' ? activeFlag : true }"
          @click.stop="nextStep"
        >
          {{ type != "read" ? "Save" : "Edit" }}
        </div>
        <div
          class="button delete"
          v-waves="{ color: '#ffffff' }"
          @click.stop="deleteContact"
          v-if="type == 'read'"
        >
          Delete
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import AddressSelectorForPhone from "../addressSelector/addressSelectorForPhone.vue";
import { handlerUploadFileToQiniu } from "@/api/qiniu";
import { parseNumber } from "libphonenumber-js";
import * as IMApi from "@/api/im";
import Bus from "@/utils/bus.js";
import { deleteContact } from "../../../api/im";
export default {
  components: {
    AddressSelectorForPhone,
  },
  data() {
    return {
      selectIndex: 0,
      inputs: {
        contactsId: "",
        contactsProviderId: "",
        profilePhoto: "",
        name: "",
        email: "",
        fieldVos: [],
        phones: [
          {
            code: "",
            phoneNumber: "",
          },
        ],
      },
      // 上传图片的回传对象
      uploadPictureItem: null,
    };
  },
  computed: {
    contactsFields() {
      return this.$store.state.ipTelephone.contactsFields;
    },
    contactDialogData() {
      return this.$store.state.globalShare.contactDialogData;
    },
    dialogVisible() {
      return this.contactDialogData ? this.contactDialogData.dialog : false;
    },
    type() {
      return this.contactDialogData && this.contactDialogData.type
        ? this.contactDialogData.type
        : "read";
    },
    data() {
      return this.contactDialogData && this.contactDialogData.data
        ? this.contactDialogData.data
        : {};
    },
    fromType() {
      return this.contactDialogData && this.contactDialogData.fromType
        ? this.contactDialogData.fromType
        : "default";
    },
    phonesLength() {
      return this.inputs.phones.length;
    },
    activeFlag() {
      return this.inputs.name != "";
    },
  },
  mounted() {
    this.selectIndex = 0;
    // 为新增窗口添加自定义字段
    if (this.type == "add") {
      this.contactsFields.map((item) => {
        this.inputs.fieldVos.push({
          fieldId: item.fieldId,
          fieldName: item.fieldName,
        });
      });
    }
  },
  watch: {
    data: {
      handler(val) {
        if (val && Object.keys(val).length !== 0) {
          this.inputs.contactsId = val.contactsId || "";
          this.inputs.name = val.name || "";
          this.inputs.profilePhoto = val.profilePhoto || "";
          this.inputs.email = val.email || "";
          this.inputs.contactsProviderId = val.contactsProviderId || "";
          this.inputs.fieldVos =
            val.fieldVos && val.fieldVos != ""
              ? JSON.parse(JSON.stringify(val.fieldVos))
              : [];
          if (this.contactsFields.length != 0) {
            this.contactsFields.map((item) => {
              const flag = this.inputs.fieldVos.some(
                (field) => field.fieldId == item.fieldId
              );
              if (flag) {
                this.inputs.fieldVos = this.inputs.fieldVos.map((field) => {
                  if (field.fieldId == item.fieldId) {
                    field.fieldName = item.fieldName;
                  }
                  return field;
                });
              } else {
                this.inputs.fieldVos.push({
                  fieldId: item.fieldId,
                  fieldName: item.fieldName,
                });
              }
            });
          }
          if (val.phone && val.phone != "") {
            this.inputs.phones = [];
            const phones = val.phone.split(",");
            phones.map((item) => {
              const result = parseNumber(item, { extended: true });
              if (Object.keys(result).length == 0) {
                this.inputs.phones.push({
                  code: "",
                  phoneNumber: item,
                });
              } else {
                this.inputs.phones.push({
                  code: result.countryCallingCode,
                  phoneNumber: result.phone,
                });
              }
            });
            this.$forceUpdate();
          }
        }
      },
      deep: true,
      immediate: true,
    },
    // 监听图片上传进度
    "uploadPictureItem.path": {
      async handler(val, old) {
        if (val !== old && val !== "") {
          this.inputs.profilePhoto = val;
        }
      },
      deep: true,
    },
  },
  methods: {
    // 上传图片,改变用户头像
    handlerUploadFile(files) {
      handlerUploadFileToQiniu(files).then((item) => {
        this.uploadPictureItem = item;
      });
    },
    async deleteContact() {
      this.$msgbox({
        title: "",
        message: <i class="el-icon-delete" />,
        center: true,
        customClass: "imelink-delrecord-confirm",
        confirmButtonText: " ",
        cancelButtonText: " ",
        showClose: false,
        showCancelButton: true,
        cancelButtonClass: "el-icon-close",
        confirmButtonClass: "el-icon-check",
        beforeClose: async (action, instance, done) => {
          if (action == "confirm") {
            const contactDialogData = this.contactDialogData;
            if (contactDialogData.data) {
              const result = await deleteContact(
                contactDialogData.data.contactsId
              );
              if (result.code == 200) {
                await this.updateLocalEmailContacts();
                Bus.$emit("refreshContactsList");
                this.dialogBeforeClose();
              } else {
                this.$message({
                  type: "error",
                  message: result.message,
                });
              }
            }
            done();
          } else {
            done();
          }
          return false;
        },
      });
    },
    async nextStep() {
      if (this.type == "read") {
        const contactDialogData = this.contactDialogData;
        contactDialogData.type = "edit";
        this.$store.commit("setContactDialogData", contactDialogData);
      } else if (this.type == "add" && this.activeFlag) {
        const {
          name,
          email,
          phones,
          profilePhoto,
          fieldVos,
          contactsProviderId,
        } = this.inputs;
        let phone = "";
        if (phones.length != 0) {
          phones.map((item) => {
            if (item.code != "" && item.phoneNumber != "") {
              phone += `+${item.code}-${item.phoneNumber},`;
            } else if (item.phoneNumber != "") {
              phone += `${item.phoneNumber},`;
            }
          });
          if (phone.length > 0) {
            phone = phone.substring(0, phone.length - 1);
          }
        }
        const contactsFields = fieldVos.filter(
          (field) => field.fieldValue && field.fieldValue != ""
        );
        const param = {
          contactsBase: {
            email: email,
            name: name,
            phone: phone,
            profilePhoto: profilePhoto,
            contactsProviderId: contactsProviderId,
          },
          contactsFields: contactsFields,
        };
        const result = await IMApi.addNewContact(param);
        if (result.code == 200 && result.data) {
          this.inputs.contactsId = result.data.data;
          this.goReadType();
          await this.updateLocalEmailContacts();
          if (this.fromType == "readMail") {
            Bus.$emit("refreshEmailContactName");
          } else {
            Bus.$emit("refreshContactsList");
          }
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
      } else if (this.type == "edit" && this.activeFlag) {
        const {
          name,
          email,
          phones,
          profilePhoto,
          contactsId,
          fieldVos,
          contactsProviderId,
        } = this.inputs;
        let phone = "";
        if (phones.length != 0) {
          phones.map((item) => {
            if (item.code != "" && item.phoneNumber != "") {
              phone += `+${item.code}-${item.phoneNumber},`;
            } else if (item.phoneNumber != "") {
              phone += `${item.phoneNumber},`;
            }
          });
          if (phone.length > 0) {
            phone = phone.substring(0, phone.length - 1);
          }
        }
        const contactsFields = fieldVos.filter(
          (field) => field.fieldValue && field.fieldValue != ""
        );
        const param = {
          contactsId: contactsId,
          contactsBase: {
            email: email,
            name: name,
            phone: phone,
            profilePhoto: profilePhoto,
            contactsProviderId: contactsProviderId,
          },
          contactsFields: contactsFields,
        };
        const result = await IMApi.modifyContact(param);
        if (result.code == 200 && result.data) {
          this.goReadType();
          await this.updateLocalEmailContacts();
          if (this.fromType == "readMail") {
            Bus.$emit("refreshEmailContactName");
          } else {
            Bus.$emit("refreshContactsList");
          }
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
      }
    },
    goReadType() {
      if (this.type != "read") {
        const contactDialogData = this.contactDialogData;
        contactDialogData.type = "read";
        this.$store.commit("setContactDialogData", contactDialogData);
      }
    },
    dialogBeforeClose() {
      if (this.dialogVisible) {
        this.$store.commit("setContactDialogData", null);
        this.$nextTick(() => {
          Object.assign(this.$data, this.$options.data.call(this));
        });
      }
    },
    selectThisAreaCode(areaCode) {
      this.inputs.phones[this.selectIndex].code = areaCode;
    },
    addPhone() {
      const lastIndex = this.phonesLength - 1;
      if (this.inputs.phones[lastIndex].phoneNumber != "") {
        this.inputs.phones.push({
          code: "",
          phoneNumber: "",
        });
      }
    },
    deletePhone(index) {
      this.inputs.phones.splice(index, 1);
    },
  },
};
</script>
<style lang="stylus" scoped>
.contactDialog
  .dialogBody
    width: 100%;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position relative;
    .container
      width: 100%;
      flex: 1;
      min-height: 0;
      .photoBox
        width: 74px;
        height: 74px;
        border-radius: 50%;
        background: rgba(0,0,0,0.4);
        margin: 40px auto 50px auto;
        position: relative;
        flex-shrink: 0;
        overflow hidden;
        .iconStyle
          font-size: 30px;
        &:hover
          .upload
            opacity: 1;
        .upload
          z-index 1;
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor pointer;
          border-radius: 50%;
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: opacity 0.5s;
          background: rgba(0,0,0,0.4);
          .iconStyle
            font-size: 35px;
            color: white;
      .scroll
        width: 100%;
        flex: 1;
        min-height: 0;
        max-height: 350px;
        overflow-x: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar
          width: 10px;
        &::-webkit-scrollbar-thumb
          border-radius: 5px;
          box-shadow: inset 0 0 5px rgba(100, 100, 100, 0.2);
          background: rgba(100, 100, 100, 0.2);
        .inputBox
          width: 410px;
          min-height: 34px;
          margin: auto;
          .fieldBox
            min-height: 34px;
            min-width: 0;
            border-radius: 17px;
            background: #F5F5F5;
            padding 8px 10px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            color #000000;
            font-size: 14px;
            line-height: 18px;
            box-sizing: border-box;
          .iconBox
            width: 34px;
            height: 34px;
            border-radius: 50%;
            background: #F5F5F5;
            margin-right: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            .iconStyle
              font-size: 20px;
              color #333333;
              font-weight: bold;
          .phoneBox
            min-height: 34px;
            flex: 1;
            min-width: 0;
            margin-bottom: 15px;
            .phoneOutter
              display: flex;
              position: relative;
              align-items: flex-start;
              justify-content: flex-start;
              margin: 10px 0 5px 0;
              &:hover
                .deletePhone
                  display: block;
              .inputBody
                margin: 0;
              .select
                max-width: 55px;
                height: 34px;
                margin-right: 10px;
                .selectButton
                  width: 100%;
                  height: 100%;
                  display: flex;
                  cursor: pointer;
                  align-items: center;
                  justify-content: flex-start;
                  font-size: 14px;
                  color #000;
                  .positionIcon
                    font-size: 24px;
                    color #B3B3B3;
                  .selectIcon
                    font-size: 14px;
                    color #B3B3B3;
                    margin-left: 3px;
              .inputButton
                width: 26px;
                height: 26px;
                top: 50%;
                right: 0;
                position: absolute;
                cursor pointer;
                .iconStyle
                  font-size: 26px;
              .deletePhone
                display: none;
                transform: translate3d(-10px, -50%, 0);
                .iconStyle
                  color #FF0000;
              .addPhone
                transform: translate3d(35px, -50%, 0);
                .iconStyle
                  color #33CC66;
              .phoneText
                width: 100%;
                height: 34px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                .phoneStyle
                  font-size: 14px;
                  color: #33CC66;
                  cursor pointer;
          .line
            width: 100%;
            height: 1px;
            background: #F1F3F4;
            margin-bottom: 15px;
          .inputBody
            min-height: 34px;
            flex: 1;
            border-radius: 17px;
            margin: 10px 0 5px 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            position: relative;
            .required
              color: red;
              font-size: 20px;
              font-weight: bold;
              position: absolute;
              right: 12px;
              top: 50%;
              transform: translate3d(0,-50%,0);
            .textStyle
              font-size: 14px;
              color: #000000;
              padding: 0 10px;
        .customBox
          width: 410px;
          overflow hidden;
          margin: auto;
    .footer
      width: 100%;
      margin: 30px 0 40px 0;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      .button
        height: 34px;
        width: 100px;
        border-radius: 17px;
        background: #e6e6e6;
        cursor: not-allowed;
        color: rgba(0,0,0,0.6);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        user-select none;
      .active
        background: #33CC66 !important;
        cursor pointer !important;
        color: white !important;
      .delete
        background: #E42B2B !important;
        color: white !important;
        margin-left: 20px;
        cursor: pointer !important;
    .deleteIconStyle
      position: absolute;
      top 18px;
      right: 18px;
      font-size: 18px;
      color: #000000;
      cursor pointer;
</style>
<style lang="stylus">
.imelink-delrecord-confirm
  width: 220px;
  .el-icon-delete, .el-icon-document-copy
    font-size: 38px;
    position: relative;
    top: -12px;
  b
    font-size: 30px;
    position: relative;
    top: -12px;
    padding-left: 4px;
  span
    display: none;
.contactDialog
  width: 36.5vw;
  max-width: 700px;
  min-width: 520px
  position: relative;
  border-radius: 18px;
  overflow hidden;
  .photoBox
    .avatar-uploader
      width: 100%;
      height: 100%;
      .el-upload
        width 100%;
        height 100%;
  .el-dialog__header
    display: none;
  .el-dialog__body
    padding 0;
    .inputBox
      .el-input
        .el-input__inner
          border: none;
          background-color: transparent;
          height: 34px;
          line-height: 34px;
</style>
